<template>

<loading v-if="loading"></loading>
<div class="main" :class="sectionsClass" v-else-if="!loading && page">
	<h1>{{ page.description }}</h1>
	<div v-html="page.content"></div>

	<iframe :src="page.script_identifier" style="border: 0" width="100%" height="600" frameborder="0" scrolling="no" v-if="page.page_type_code == 'GOOGLE_CALENDAR'"></iframe>

	<iframe id="JotFormIFrame" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" :src="page.script_identifier" frameborder="0"
	 style=" min-width: 100%; height:800px; border:none;" scrolling="no" v-if="page.page_type_code == 'JOTFORM'"></iframe>
</div>
<div class="main" :class="sectionsClass" v-else>
    <h1>Page not found</h1>
    <p>Please check the link and try again.</p>
</div>

</template>

<script>
// For JotForms iframe
window.handleIFrameMessage = function(e){
	if (!e.data || typeof e.data != 'string') { return; }
	var args = e.data.split(":");
	var iframe = document.getElementById("JotFormIFrame");
	if (!iframe) { return; }
	switch (args[0]) {
		case "scrollIntoView":
			iframe.scrollIntoView();
			break;
		case "setHeight":
			iframe.style.height = args[1] + "px";
			break;
		case "collapseErrorPage":
			if (iframe.clientHeight > window.innerHeight) {
				iframe.style.height = window.innerHeight + "px";
			}
			break;
		case "reloadPage":
			window.location.reload();
			break;
		case "loadScript":
			var src = args[1];
			if (args.length > 3) { src = args[1] + ':' + args[2];
			} var script = document.createElement('script');
			script.src = src;
			script.type = 'text/javascript';
			document.body.appendChild(script);
			break;
		case "exitFullscreen":
			if (window.document.exitFullscreen) window.document.exitFullscreen();
			else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen();
			else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen();
			else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen();
			else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
			break;
	}
	var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
	if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
		var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
		iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
	}
}

if (window.addEventListener) {
	window.addEventListener("message", handleIFrameMessage, false);
} else if (window.attachEvent) {
	window.attachEvent("onmessage", handleIFrameMessage);
}

export default {
	name: 'content-page',
	props: [ 'link_name' ],
	data() {
		return {
            loading: false,
			page: {}
		}
	},
	created() {
		this.fetchData()
	},
	computed:{
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return

			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		},
		today() {
			var today = new Date()
			var month = (today.getMonth() + 1).toString().padStart(2, '0')
			var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
			return today.getFullYear() + "-" + month + "-" + today.getDate() + "T" + today.getHours() + ":00:00Z"
		},
		timeZone() {
			return Intl.DateTimeFormat().resolvedOptions().timeZone
		}
	},
	watch: {
		link_name(newVal, oldVal) {
			if (newVal && newVal != oldVal) {
				this.fetchData()
			}
		}
	},
	methods: {
		fetchData() {
			var data = {
				link_name: this.link_name
			}
			this.apiCall({ destination: 'get_item_page', data: data }).then(obj => {
                if (obj.status == "error") {
                    this.page = null
                } else {
					this.page = obj.page
					this.convertOembedToIFrame()
					// Analytics
					setTimeout(() => {
						this.$gtag.pageview({
							page_title: this.page.description,
							page_path: this.$route.path,
						})
					}, 1000)
                }
            }).catch(error => {
                console.log(error)
            })
		},
		convertOembedToIFrame() {
			setTimeout(() => {
				let media = document.querySelectorAll('figure.media oembed')
				media.forEach(async element => {
					let url = element.getAttribute('url')
					let api = ''
					if (url.indexOf('vimeo.com') > -1) {
						api = 'https://vimeo.com/api/oembed.json?url='
					} else {
						api = 'https://www.youtube.com/oembed?url='
					}
					await fetch(api + url).then(response => {
						return response.json()
					}).then(obj => {
						let iframe = new DOMParser().parseFromString(obj.html, "text/html").body.firstElementChild
						let ratio = obj.width / obj.height
						iframe.width = 640
						iframe.height = 640 / ratio
						element.parentNode.replaceChild(iframe, element)
					})
				});
			}, 500)
		}
	}
}
</script>

<style lang="scss">
figure.image_resized {
	img { width: 100%; }
}
figure.media {
	margin: 0;
	padding: 0;
	text-align: center;
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
	iframe {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}
a {
	color: #005796;
}
</style>
